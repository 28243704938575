export const approveOptions = [
  { item: 1, name: 'Aprovar' },
  { item: 2, name: 'Não aprovar' }
];

export const lastDaysOptions = [
  { value: 1, text: 'Último dia' },
  { value: 3, text: 'Últimos 3 dias' },
  { value: 7, text: 'Últimos 7 dias' },
  { value: 14, text: 'Últimos 14 dias' },
  { value: 31, text: 'Últimos 31 dias' }
];

export const pendenceOptions = [
  { value: null, text: 'Todos' },
  { value: 1, text: 'Atrasado' },
  { value: 2, text: 'Pontual' }
];

export const specialtyOptions = [
  { value: null, text: 'Todos' },
  { value: 1, text: 'VIDA' },
  { value: 2, text: 'SONHOS E LIBERDADE FINANCEIRA' },
  { value: 3, text: 'PROTEÇÃO DO PATRIMÔNIO' },
  { value: 4, text: 'CRÉDITO' },
  { value: 5, text: 'CÂMBIO' },
  { value: 6, text: 'REAL ESTATE' }
];

export const typeOptions = [
  { value: null, text: 'Todos' },
  { value: 1, text: 'A FAZER' },
  { value: 2, text: 'EM ANDAMENTO' },
  { value: 3, text: 'CONCLUÍDOS' },
  { value: 4, text: 'RECUSADOS' }
];

export const statusStudy = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Aprovado' },
  { value: 4, text: 'Em análise pelo cliente' },
  { value: 2, text: 'Ajustar' },
  { value: 3, text: 'Recusado' }
];

export const educationDegreeOptions = [
  { value: '', text: 'Selecione' },
  { value: '1', text: 'Educação Infantil' },
  { value: '2', text: 'Ensino Fundamental' },
  { value: '3', text: 'Ensino Médio' },
  { value: '4', text: 'Superior (Graduação)' },
  { value: '5', text: 'Pós-graduação' },
  { value: '6', text: 'Mestrado' },
  { value: '7', text: 'Doutorado' }
];

export const professionOptions = [
  { value: '', text: 'Selecione' },
  { value: '1', text: 'Professor' },
  { value: '2', text: 'Técnico' },
  { value: '3', text: 'Especialista' },
  { value: '4', text: 'Gestor' },
  { value: '5', text: 'Planejador' },
  { value: '6', text: 'Consultor' },
  { value: '7', text: 'Empreendedor' }
];

export const communionRegimeOptions = [
  { value: '', text: 'Selecione' },
  { value: 'RC1', text: 'Comunhão parcial de bens' },
  { value: 'RC2', text: 'Comunhão universal de bens' },
  { value: 'RC3', text: 'Separação de bens' },
  { value: 'RC4', text: 'Participação final nos Aquestos' }
];

export const maritalStatusOptions = [
  { value: '', text: 'Selecione' },
  { value: 'S', text: 'Solteiro' },
  { value: 'C', text: 'Casado' },
  { value: 'SJ', text: 'Separado' },
  { value: 'D', text: 'Divorciado' },
  { value: 'V', text: 'Viúvo' },
  { value: 'UE', text: 'União Estável' },
  { value: 'O', text: 'Outros' }
];

export const priorityOrderOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: '1' },
  { value: 2, text: '2' },
  { value: 3, text: '3' },
  { value: 4, text: '4' },
  { value: 5, text: '5' }
];

export const priorityOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Educação dos filhos' },
  {
    value: 2,
    text: 'Manutenção das despesas na sua ausência'
  },
  {
    value: 3,
    text: 'Liberdade financeira, reduzir o ritmo ou aposentadoria'
  },
  {
    value: 4,
    text: 'Sonhos e projetos de vida'
  },
  { value: 5, text: 'Gastos com saúde' },
  { value: 6, text: 'Outros' }
];

export const dreamTypeOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Imóvel', text: 'Imóvel' },
  { value: 'Imóvel no exterior', text: 'Imóvel no exterior' },
  { value: 'Veículo', text: 'Veículo' },
  { value: 'Outros', text: 'Outros' }
];

export const dreamYearOptions = [
  { value: null, text: 'Selecione' },
  { value: '1 ano', text: '1 ano' },
  { value: '2 anos', text: '2 anos' },
  { value: '3 anos', text: '3 anos' },
  { value: '4 anos', text: '4 anos' },
  { value: '5 anos', text: '5 anos' },
  { value: '6 anos', text: '6 anos' },
  { value: '7 anos', text: '7 anos' },
  { value: '8 anos', text: '8 anos' },
  { value: '9 anos', text: '9 anos' },
  { value: '10 anos ou mais', text: '10 anos ou mais' }
];

export const tipoSeguroOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Vida Inteira', text: 'Vida Inteira' },
  { value: 'Dotal Misto', text: 'Dotal Misto' },
  { value: 'Universal life', text: 'Universal life' },
  {
    value: 'Seguro de vida tradicional',
    text: 'Seguro de vida tradicional'
  },
  { value: 'Temporário', text: 'Temporário' },
  { value: 'Temporário decrescente', text: 'Temporário decrescente' },
  {
    value: 'Assistência ou auxílio funeral',
    text: 'Assistência ou auxílio funeral'
  }
];

export const tipoPessoaOptions = [
  { value: null, text: 'Selecione' },
  { value: 'PF', text: 'Pessoa física' },
  { value: 'PJ', text: 'Pessoa jurídica' }
];

export const cilindradaOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Ate 1.000 cc' },
  { value: 2, text: 'Acima de 1.000 cc' }
];

export const tipoPessoaSaudeOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Pessoa física', text: 'Pessoa física' },
  { value: 'Pessoa jurídica', text: 'Pessoa jurídica' },
  { value: 'Entidade de Classe', text: 'Entidade de Classe' },
  { value: 'Benefício da empresa', text: 'Benefício da empresa' }
];

export const fumanteOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Fumante' },
  { value: 2, text: 'Não fumante' },
  { value: 3, text: 'Ex fumante' }
];

export const tipoCoberturaOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Regional', text: 'Regional' },
  { value: 'Nacional', text: 'Nacional' },
  { value: 'Nacional + Exterior', text: 'Nacional + Exterior' }
];

export const acomodacaoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Ambulatorial', text: 'Ambulatorial' },
  { value: 'Apartamento', text: 'Apartamento' }
];

export const tipoPlanoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'PGBL', text: 'PGBL' },
  { value: 'VGBL', text: 'VGBL' },
  { value: 'Indefinido', text: 'Indefinido' }
];

export const tributacaoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Progressiva', text: 'Progressiva' },
  { value: 'Regressiva', text: 'Regressiva' }
];

export const tipoRendaOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Vitalícia', text: 'Vitalícia' },
  { value: 'Temporária', text: 'Temporária' }
];

export const tipoDeclaracaoIrOptions = [
  { value: null, text: 'Selecione' },
  { value: 'simples', text: 'Simplificado' },
  { value: 'completa', text: 'Completa sem PGBL' },
  { value: 'completa_pgbl_maximo', text: 'Completa com PGBL máximo' }
];

export const formaConstrucaoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Financiamento', text: 'Financiamento' },
  { value: 'Consórcio', text: 'Consórcio' }
];

export const indiceCorrecaoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'INCC', text: 'INCC' },
  { value: 'IPCA', text: 'IPCA' },
  { value: 'TR', text: 'TR' },
  { value: 'INPC', text: 'INPC' },
  { value: 'FIPE', text: 'FIPE' }
];

export const parentescoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Filho(a)', text: 'Filho(a)' },
  { value: 'Conjugê', text: 'Conjugê' },
  { value: 'Pai', text: 'Pai' },
  { value: 'Mãe', text: 'Mãe' },
  { value: 'Outro', text: 'Outro' }
];

export const parentescoOutroDependenteOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Pai', text: 'Pai' },
  { value: 'Mãe', text: 'Mãe' },
  { value: 'Outro', text: 'Outro' }
];

export const tempoProtecaoOptions = [
  { value: null, text: 'Selecione' },
  { value: 5, text: '5 anos' },
  { value: 10, text: '10 anos' },
  { value: 15, text: '15 anos' },
  { value: 20, text: '20 anos' },
  { value: 25, text: '25 anos' },
  { value: 30, text: '30 anos' },
  { value: 999, text: 'Vitalício' }
];

export const sexOptions = [
  { value: null, text: 'Selecione' },
  { value: 'M', text: 'Masculino' },
  { value: 'F', text: 'Feminino' },
  { value: 'O', text: 'Outro' }
];

export const optionsStatus = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Prospecção' },
  { value: 2, text: 'Levantamento de necessidades' },
  { value: 3, text: 'Direcionamento do consultor' },
  { value: 4, text: 'Cotação' },
  { value: 5, text: 'Resultado do estudo' },
  { value: 6, text: 'Apresentação do estudo' },
  { value: 7, text: 'Ajustes' },
  { value: 8, text: 'Fechamento' },
  { value: 9, text: 'Protocolar no fornecedor' },
  { value: 10, text: 'Efetivação no fornecedor' }
];

export const perfilInvestidorOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Conservador', text: 'Conservador' },
  { value: 'Moderado', text: 'Moderado' },
  { value: 'Balanceado', text: 'Balanceado' },
  { value: 'Sofisticado', text: 'Sofisticado' },
  { value: 'Especialista', text: 'Especialista' }
];

export const tipoBemOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Imóvel', text: 'Imóvel' },
  { value: 'Veículos', text: 'Veículos' },
  { value: 'Outros', text: 'Outros' }
];

export const liquidezOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'até 5 dias' },
  { value: 2, text: 'até 90 dias' },
  { value: 3, text: 'até 360 dias' },
  { value: 4, text: 'até 720 dias' },
  { value: 5, text: 'acima de 720 dias' }
];

export const aportesOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Diário' },
  { value: 2, text: 'Semanal' },
  { value: 3, text: 'Mensal' },
  { value: 4, text: 'Anual' },
  { value: 5, text: 'Outros' }
];

export const tipoInvestimentoOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Renda fixa' },
  { value: 2, text: 'Multimercado' },
  { value: 3, text: 'Renda variável' },
  { value: 4, text: 'Outros' }
];

export const comunhaoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Comunhão parcial', text: 'Comunhão parcial' },
  { value: 'Comunhão universal', text: 'Comunhão universal' },
  { value: 'Separação de bens', text: 'Separação de bens' },
  {
    value: 'Participação final nos aquestos',
    text: 'Participação final nos aquestos'
  },
  { value: 'União estável', text: 'União estável' },
  { value: 'Nenhum', text: 'Nenhum' }
];

export const estadoCivilOptions = [
  { value: null, text: 'Selecione' },
  { value: 'S', text: 'Solteiro' },
  { value: 'C', text: 'Casado' },
  { value: 'SJ', text: 'Separado' },
  { value: 'D', text: 'Divorciado' },
  { value: 'V', text: 'Viúvo' },
  { value: 'UE', text: 'União Estável' },
  { value: 'O', text: 'Outros' }
];

export const fields = [
  {
    key: 'user',
    label: 'Usuário'
  },
  {
    key: 'comment',
    label: 'Comentário'
  },
  {
    key: 'product',
    label: 'Produto'
  },
  {
    key: 'actions',
    label: 'Ações'
  }
];

export const optionsDateOrder = [
  { value: 'desc', text: 'Ordem decrescente' },
  { value: 'asc', text: 'Ordem crescente' }
];

export const stageOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Preparar cotação' },
  { value: 2, text: 'Verificar resposta do cliente' },
  { value: 3, text: 'Verificar documentos' },
  { value: 4, text: 'Imputar propostas' },
  { value: 5, text: 'Cadastrar contratos/apólices' },
  { value: 6, text: 'Concluído' },
  { value: 7, text: 'Recusado' }
];

export const statusStepOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'A fazer' },
  { value: 2, text: 'Em Andamento' },
  { value: 3, text: 'Concluídos' },
  { value: 4, text: 'Recusados' }
];

export const productOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Vida' },
  { value: 2, text: 'Sonhos e liberdade financeira' },
  { value: 3, text: 'Proteção de patrimônio' },
  { value: 4, text: 'Crédito' },
  { value: 5, text: 'Câmbio' },
  { value: 6, text: 'Real Estate' }
];

export const statusOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'CADASTRO DO DIAGNÓSTICO' },
  { value: 2, text: 'RESULTADO DO EXAME' },
  { value: 3, text: 'RECEITA/COTAÇÃO' }
];

export const contactOptions = [
  { value: null, text: 'Selecione' },
  { value: 'email', text: 'Email' },
  { value: 'celular', text: 'Celular' },
  { value: 'telefone', text: 'Telefone' },
  { value: 'whatsapp', text: 'Whatsapp' }
];

export const avaliacaoNecessidadeOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Individualmente', text: 'Individualmente' },
  { value: 'Com seu cônjuge', text: 'Com seu cônjuge' }
];

export const stateOptions = [
  { value: null, text: 'Selecione' },
  { value: 'AC', text: 'Acre' },
  { value: 'AL', text: 'Alagoas' },
  { value: 'AP', text: 'Amapá' },
  { value: 'AM', text: 'Amazonas' },
  { value: 'BA', text: 'Bahia' },
  { value: 'CE', text: 'Ceará' },
  { value: 'DF', text: 'Distrito Federal' },
  { value: 'ES', text: 'Espírito Santo' },
  { value: 'GO', text: 'Goiás' },
  { value: 'MA', text: 'Maranhão' },
  { value: 'MT', text: 'Mato Grosso' },
  { value: 'MS', text: 'Mato Grosso do Sul' },
  { value: 'MG', text: 'Minas Gerais' },
  { value: 'PA', text: 'Pará' },
  { value: 'PB', text: 'Paraíba' },
  { value: 'PR', text: 'Paraná' },
  { value: 'PE', text: 'Pernambuco' },
  { value: 'PI', text: 'Piauí' },
  { value: 'RJ', text: 'Rio de Janeiro' },
  { value: 'RN', text: 'Rio Grande do Norte' },
  { value: 'RS', text: 'Rio Grande do Sul' },
  { value: 'RO', text: 'Rondônia' },
  { value: 'RR', text: 'Roraima' },
  { value: 'SC', text: 'Santa Catarina' },
  { value: 'SP', text: 'São Paulo' },
  { value: 'SE', text: 'Sergipe' },
  { value: 'TO', text: 'Tocantins' }
];

export const educationLevelOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Educação Infantil', text: 'Educação Infantil' },
  { value: 'Ensino Fundamental', text: 'Ensino Fundamental' },
  { value: 'Ensino Médio', text: 'Ensino Médio' },
  { value: 'Superior (Graduação)', text: 'Superior (Graduação)' },
  { value: 'Pós-graduação', text: 'Pós-graduação' },
  { value: 'Mestrado', text: 'Mestrado' },
  { value: 'Doutorado', text: 'Doutorado' }
];

export const workSituationOptions = [
  { value: null, text: 'Selecione' },
  { value: 'CLT', text: 'CLT' },
  { value: 'Autonômo', text: 'Autonômo' },
  { value: 'Desempregado', text: 'Desempregado' },
  { value: 'Empresário', text: 'Empresário' },
  { value: 'Vive de renda', text: 'Vive de renda' }
];

export const periodoCoberturaMorte = [
  { value: null, text: 'Selecione' },
  { value: 5, text: '5 anos' },
  { value: 10, text: '10 anos' },
  { value: 15, text: '15 anos' },
  { value: 20, text: '20 anos' },
  { value: 25, text: '25 anos' },
  { value: 30, text: '30 anos' },
  { value: 999, text: 'Vitalício' }
];

export const periodoCoberturaVida = [
  { value: null, text: 'Selecione' },
  { value: 1, text: '1 ano' },
  { value: 5, text: '5 anos' }
];

export const periodoCoberturaInvalidez = [
  { value: null, text: 'Selecione' },
  { value: 1, text: '1 ano' },
  { value: 5, text: '5 anos' },
  { value: 60, text: 'até 60 anos' },
  { value: 65, text: 'até 65 anos' },
  { value: 70, text: 'até 70 anos' },
  { value: 75, text: 'até 75 anos' },
  { value: 999, text: 'Vitalício' }
];

export const formaPagamentoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Débito', text: 'Débito' },
  { value: 'Boleto', text: 'Boleto' },
  { value: 'Cartão', text: 'Cartão' }
];

export const periodicidadePagamentoOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Mensal' },
  { value: 2, text: 'Anual' }
];

export const tipoDocumentoIdentificacaoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'RG', text: 'RG' },
  { value: 'CNH', text: 'CNH' },
  { value: 'Identificação Funcional', text: 'Identificação Funcional' },
  { value: 'Passaporte', text: 'Passaporte' }
];

export const occupationOptions = [
  {
    value: null,
    text: 'Selecione'
  },
  {
    value: 'Administrador de Banco de Dados',
    text: 'Administrador de Banco de Dados'
  },
  {
    value: 'Açougueiro',
    text: 'Açougueiro'
  },
  {
    value: 'Acompanhante de Idosos',
    text: 'Acompanhante de Idosos'
  },
  {
    value: 'Acupunturista',
    text: 'Acupunturista'
  },
  {
    value: 'Administrador de Empresas',
    text: 'Administrador de Empresas'
  },
  {
    value: 'Administrador de Redes',
    text: 'Administrador de Redes'
  },
  {
    value: 'Administrador de Redes Junior',
    text: 'Administrador de Redes Junior'
  },
  {
    value: 'Administrador de Redes Pleno',
    text: 'Administrador de Redes Pleno'
  },
  {
    value: 'Administrador de Redes Sênior',
    text: 'Administrador de Redes Sênior'
  },
  {
    value: 'Administrador de Sistemas',
    text: 'Administrador de Sistemas'
  },
  {
    value: 'Administrador Hospitalar',
    text: 'Administrador Hospitalar'
  },
  {
    value: 'Administrador Público',
    text: 'Administrador Público'
  },
  {
    value: 'Advogado',
    text: 'Advogado'
  },
  {
    value: 'Advogado Civil',
    text: 'Advogado Civil'
  },
  {
    value: 'Aeronauta',
    text: 'Aeronauta'
  },
  {
    value: 'Afiador de Ferramentas',
    text: 'Afiador de Ferramentas'
  },
  {
    value: 'Agente Comunitário de Saúde',
    text: 'Agente Comunitário de Saúde'
  },
  {
    value: 'Agente de Defesa Sanitária',
    text: 'Agente de Defesa Sanitária'
  },
  {
    value: 'Agente de Viagens',
    text: 'Agente de Viagens'
  },
  {
    value: 'Agente Funerário',
    text: 'Agente Funerário'
  },
  {
    value: 'Agente Penitenciário',
    text: 'Agente Penitenciário'
  },
  {
    value: 'Agricultor',
    text: 'Agricultor'
  },
  {
    value: 'Agrônomo',
    text: 'Agrônomo'
  },
  {
    value: 'Ajudante de Motorista',
    text: 'Ajudante de Motorista'
  },
  {
    value: 'Ajudante de Transportes',
    text: 'Ajudante de Transportes'
  },
  {
    value: 'Alfaiate',
    text: 'Alfaiate'
  },
  {
    value: 'Almirante',
    text: 'Almirante'
  },
  {
    value: 'Almoxarife',
    text: 'Almoxarife'
  },
  {
    value: 'Ambientalista',
    text: 'Ambientalista'
  },
  {
    value: 'Ambulante',
    text: 'Ambulante'
  },
  {
    value: 'Analista Administrativo',
    text: 'Analista Administrativo'
  },
  {
    value: 'Analista Administrativo de Vendas',
    text: 'Analista Administrativo de Vendas'
  },
  {
    value: 'Analista Administrativo Financeiro',
    text: 'Analista Administrativo Financeiro'
  },
  {
    value: 'Analista Ambiental',
    text: 'Analista Ambiental'
  },
  {
    value: 'Analista Comercial Interno',
    text: 'Analista Comercial Interno'
  },
  {
    value: 'Analista Comercial Externo',
    text: 'Analista Comercial Externo'
  },
  {
    value: 'Analista de Atendimento a Clientes',
    text: 'Analista de Atendimento a Clientes'
  },
  {
    value: 'Analista de Atendimento Comercial',
    text: 'Analista de Atendimento Comercial'
  },
  {
    value: 'Analista de Banco',
    text: 'Analista de Banco'
  },
  {
    value: 'Analista de Banco de Dados',
    text: 'Analista de Banco de Dados'
  },
  {
    value: 'Analista de Cargos e Salários',
    text: 'Analista de Cargos e Salários'
  },
  {
    value: 'Analista de Cobrança',
    text: 'Analista de Cobrança'
  },
  {
    value: 'Analista de Comércio Exterior',
    text: 'Analista de Comércio Exterior'
  },
  {
    value: 'Analista de Compras',
    text: 'Analista de Compras'
  },
  {
    value: 'Analista de Comunicação',
    text: 'Analista de Comunicação'
  },
  {
    value: 'Analista de Contabilidade',
    text: 'Analista de Contabilidade'
  },
  {
    value: 'Analista de Contas a Pagar e Receber',
    text: 'Analista de Contas a Pagar e Receber'
  },
  {
    value: 'Analista de Controladoria',
    text: 'Analista de Controladoria'
  },
  {
    value: 'Analista de Controladoria Junior',
    text: 'Analista de Controladoria Junior'
  },
  {
    value: 'Analista de Controladoria Pleno',
    text: 'Analista de Controladoria Pleno'
  },
  {
    value: 'Analista de Controladoria Sênior',
    text: 'Analista de Controladoria Sênior'
  },
  {
    value: 'Analista de Controle de Qualidade',
    text: 'Analista de Controle de Qualidade'
  },
  {
    value: 'Analista de Crédito e Cobrança',
    text: 'Analista de Crédito e Cobrança'
  },
  {
    value: 'Analista de Custos',
    text: 'Analista de Custos'
  },
  {
    value: 'Analista de Departamento Pessoal',
    text: 'Analista de Departamento Pessoal'
  },
  {
    value: 'Analista de Desenvolvimento de Produto',
    text: 'Analista de Desenvolvimento de Produto'
  },
  {
    value: 'Analista de Desenvolvimento de Sistemas',
    text: 'Analista de Desenvolvimento de Sistemas'
  },
  {
    value: 'Analista de Desenvolvimento Web',
    text: 'Analista de Desenvolvimento Web'
  },
  {
    value: 'Analista de Distribuição',
    text: 'Analista de Distribuição'
  },
  {
    value: 'Analista de Ecommerce',
    text: 'Analista de Ecommerce'
  },
  {
    value: 'Analista de Estoque',
    text: 'Analista de Estoque'
  },
  {
    value: 'Analista de Engenharia Elétrica',
    text: 'Analista de Engenharia Elétrica'
  },
  {
    value: 'Analista Financeiro',
    text: 'Analista Financeiro'
  },
  {
    value: 'Analista de Fiscal',
    text: 'Analista de Fiscal'
  },
  {
    value: 'Analista Químico',
    text: 'Analista Químico'
  },
  {
    value: 'Anestesiologista',
    text: 'Anestesiologista'
  },
  {
    value: 'Angiologista',
    text: 'Angiologista'
  },
  {
    value: 'Antropólogo',
    text: 'Antropólogo'
  },
  {
    value: 'Apicultor',
    text: 'Apicultor'
  },
  {
    value: 'Apresentador',
    text: 'Apresentador'
  },
  {
    value: 'Argumentista',
    text: 'Argumentista'
  },
  {
    value: 'Armador',
    text: 'Armador'
  },
  {
    value: 'Armeiro',
    text: 'Armeiro'
  },
  {
    value: 'Arquiteto',
    text: 'Arquiteto'
  },
  {
    value: 'Arquiteto de Interiores',
    text: 'Arquiteto de Interiores'
  },
  {
    value: 'Arquiteto Paisagista',
    text: 'Arquiteto Paisagista'
  },
  {
    value: 'Arquiteto Urbanista',
    text: 'Arquiteto Urbanista'
  },
  {
    value: 'Arquiteto Projetista',
    text: 'Arquiteto Projetista'
  },
  {
    value: 'Arquiteto Vendedor',
    text: 'Arquiteto Vendedor'
  },
  {
    value: 'Arquivista',
    text: 'Arquivista'
  },
  {
    value: 'Arrumadeira',
    text: 'Arrumadeira'
  },
  {
    value: 'Artista de Espetáculo de Diversões',
    text: 'Artista de Espetáculo de Diversões'
  },
  {
    value: 'Artista Plástico',
    text: 'Artista Plástico'
  },
  {
    value: 'Ascensorista',
    text: 'Ascensorista'
  },
  {
    value: 'Assessor de Imprensa',
    text: 'Assessor de Imprensa'
  },
  {
    value: 'Analista de Fundição',
    text: 'Analista de Fundição'
  },
  {
    value: 'Analista de Informática',
    text: 'Analista de Informática'
  },
  {
    value: 'Analista de Investimentos',
    text: 'Analista de Investimentos'
  },
  {
    value: 'Analista de Logística',
    text: 'Analista de Logística'
  },
  {
    value: 'Analista de Loja',
    text: 'Analista de Loja'
  },
  {
    value: 'Analista de Manutenção',
    text: 'Analista de Manutenção'
  },
  {
    value: 'Analista de Manutenção Elétrica',
    text: 'Analista de Manutenção Elétrica'
  },
  {
    value: 'Analista de Marketing',
    text: 'Analista de Marketing'
  },
  {
    value: 'Analista de PCP',
    text: 'Analista de PCP'
  },
  {
    value: 'Analista de Produção',
    text: 'Analista de Produção'
  },
  {
    value: 'Analista de Programação',
    text: 'Analista de Programação'
  },
  {
    value: 'Analista de Publicidade e Propaganda',
    text: 'Analista de Publicidade e Propaganda'
  },
  {
    value: 'Analista de Qualidade',
    text: 'Analista de Qualidade'
  },
  {
    value: 'Analista de Recrutamento e Seleção',
    text: 'Analista de Recrutamento e Seleção'
  },
  {
    value: 'Analista de Recursos Humanos',
    text: 'Analista de Recursos Humanos'
  },
  {
    value: 'Analista de Redes',
    text: 'Analista de Redes'
  },
  {
    value: 'Analista de Relações Públicas',
    text: 'Analista de Relações Públicas'
  },
  {
    value: 'Analista de Representação Comercial',
    text: 'Analista de Representação Comercial'
  },
  {
    value: 'Analista de SAC',
    text: 'Analista de SAC'
  },
  {
    value: 'Analista de Segurança Patrimonial',
    text: 'Analista de Segurança Patrimonial'
  },
  {
    value: 'Analista de Seguros',
    text: 'Analista de Seguros'
  },
  {
    value: 'Analista de Sistemas',
    text: 'Analista de Sistemas'
  },
  {
    value: 'Analista de Suporte Técnico',
    text: 'Analista de Suporte Técnico'
  },
  {
    value: 'Analista de Suprimentos',
    text: 'Analista de Suprimentos'
  },
  {
    value: 'Analista de Tecnologia da Informação (TI)',
    text: 'Analista de Tecnologia da Informação (TI)'
  },
  {
    value: 'Analista de Telecomunicações',
    text: 'Analista de Telecomunicações'
  },
  {
    value: 'Analista de Telemarketing Ativo',
    text: 'Analista de Telemarketing Ativo'
  },
  {
    value: 'Analista de Telemarketing Receptivo',
    text: 'Analista de Telemarketing Receptivo'
  },
  {
    value: 'Analista de Tesouraria',
    text: 'Analista de Tesouraria'
  },
  {
    value: 'Analista de Transportes',
    text: 'Analista de Transportes'
  },
  {
    value: 'Analista de Treinamento',
    text: 'Analista de Treinamento'
  },
  {
    value: 'Analista de Treinamento Sênior',
    text: 'Analista de Treinamento Sênior'
  },
  {
    value: 'Analista Econômico',
    text: 'Analista Econômico'
  },
  {
    value: 'Assistente Comercial Interno',
    text: 'Assistente Comercial Interno'
  },
  {
    value: 'Assistente de Almoxarifado',
    text: 'Assistente de Almoxarifado'
  },
  {
    value: 'Assistente de Arquitetura',
    text: 'Assistente de Arquitetura'
  },
  {
    value: 'Assistente Administrativo',
    text: 'Assistente Administrativo'
  },
  {
    value: 'Assistente de Atendimento a Clientes',
    text: 'Assistente de Atendimento a Clientes'
  },
  {
    value: 'Assistente de Atendimento Comercial',
    text: 'Assistente de Atendimento Comercial'
  },
  {
    value: 'Assistente de Cobrança',
    text: 'Assistente de Cobrança'
  },
  {
    value: 'Assistente de Comércio Exterior',
    text: 'Assistente de Comércio Exterior'
  },
  {
    value: 'Assistente de Compras',
    text: 'Assistente de Compras'
  },
  {
    value: 'Assistente de Comunicação',
    text: 'Assistente de Comunicação'
  },
  {
    value: 'Assistente de Contabilidade',
    text: 'Assistente de Contabilidade'
  },
  {
    value: 'Assistente de Contas a Pagar e Receber',
    text: 'Assistente de Contas a Pagar e Receber'
  },
  {
    value: 'Assistente de Controladoria',
    text: 'Assistente de Controladoria'
  },
  {
    value: 'Assistente de Controle de Qualidade',
    text: 'Assistente de Controle de Qualidade'
  },
  {
    value: 'Assistente de Cozinha',
    text: 'Assistente de Cozinha'
  },
  {
    value: 'Assistente de Crédito e Cobrança',
    text: 'Assistente de Crédito e Cobrança'
  },
  {
    value: 'Assistente de Custos',
    text: 'Assistente de Custos'
  },
  {
    value: 'Assistente de Departamento Pessoal',
    text: 'Assistente de Departamento Pessoal'
  },
  {
    value: 'Assistente de Desenvolvimento de Produto',
    text: 'Assistente de Desenvolvimento de Produto'
  },
  {
    value: 'Assistente de Desenvolvimento de Sistemas',
    text: 'Assistente de Desenvolvimento de Sistemas'
  },
  {
    value: 'Assistente de Distribuição',
    text: 'Assistente de Distribuição'
  },
  {
    value: 'Assistente de Ecommerce',
    text: 'Assistente de Ecommerce'
  },
  {
    value: 'Assistente de Enfermagem',
    text: 'Assistente de Enfermagem'
  },
  {
    value: 'Assistente de Engenharia Elétrica',
    text: 'Assistente de Engenharia Elétrica'
  },
  {
    value: 'Assistente de Estoque',
    text: 'Assistente de Estoque'
  },
  {
    value: 'Assistente Financeiro',
    text: 'Assistente Financeiro'
  },
  {
    value: 'Assistente de Informática',
    text: 'Assistente de Informática'
  },
  {
    value: 'Assistente de Jornalismo',
    text: 'Assistente de Jornalismo'
  },
  {
    value: 'Assistente de Limpeza',
    text: 'Assistente de Limpeza'
  },
  {
    value: 'Assistente de Logística',
    text: 'Assistente de Logística'
  },
  {
    value: 'Assistente de Loja',
    text: 'Assistente de Loja'
  },
  {
    value: 'Assistente de Manutenção',
    text: 'Assistente de Manutenção'
  },
  {
    value: 'Assistente de Manutenção Elétrica',
    text: 'Assistente de Manutenção Elétrica'
  },
  {
    value: 'Assistente de Marketing',
    text: 'Assistente de Marketing'
  },
  {
    value: 'Assistente de PCP',
    text: 'Assistente de PCP'
  },
  {
    value: 'Assistente de Produção',
    text: 'Assistente de Produção'
  },
  {
    value: 'Assistente de Publicidade e Propaganda',
    text: 'Assistente de Publicidade e Propaganda'
  },
  {
    value: 'Assistente de Qualidade',
    text: 'Assistente de Qualidade'
  },
  {
    value: 'Assistente de Recrutamento e Seleção',
    text: 'Assistente de Recrutamento e Seleção'
  },
  {
    value: 'Assistente de Recursos Humanos',
    text: 'Assistente de Recursos Humanos'
  },
  {
    value: 'Assistente de Representação Comercial',
    text: 'Assistente de Representação Comercial'
  },
  {
    value: 'Assistente de SAC',
    text: 'Assistente de SAC'
  },
  {
    value: 'Assistente de Seguros',
    text: 'Assistente de Seguros'
  },
  {
    value: 'Assistente de Sistemas',
    text: 'Assistente de Sistemas'
  },
  {
    value: 'Assistente de Suporte Técnico',
    text: 'Assistente de Suporte Técnico'
  },
  {
    value: 'Assistente de Suprimentos',
    text: 'Assistente de Suprimentos'
  },
  {
    value: 'Assistente de Tecnologia da Informação (TI)',
    text: 'Assistente de Tecnologia da Informação (TI)'
  },
  {
    value: 'Assistente de Telecomunicações',
    text: 'Assistente de Telecomunicações'
  },
  {
    value: 'Assistente de Telemarketing Ativo',
    text: 'Assistente de Telemarketing Ativo'
  },
  {
    value: 'Assistente de Telemarketing Receptivo',
    text: 'Assistente de Telemarketing Receptivo'
  },
  {
    value: 'Assistente de Tesouraria',
    text: 'Assistente de Tesouraria'
  },
  {
    value: 'Assistente de Transportes',
    text: 'Assistente de Transportes'
  },
  {
    value: 'Assistente de Treinamento',
    text: 'Assistente de Treinamento'
  },
  {
    value: 'Assistente de Vendas Externas',
    text: 'Assistente de Vendas Externas'
  },
  {
    value: 'Assistente Editorial',
    text: 'Assistente Editorial'
  },
  {
    value: 'Assistente Fiscal',
    text: 'Assistente Fiscal'
  },
  {
    value: 'Assistente Social',
    text: 'Assistente Social'
  },
  {
    value: 'Astrônomo',
    text: 'Astrônomo'
  },
  {
    value: 'Atendente',
    text: 'Atendente'
  },
  {
    value: 'Atendente de Agência',
    text: 'Atendente de Agência'
  },
  {
    value: 'Atendente Distribuidor',
    text: 'Atendente Distribuidor'
  },
  {
    value: 'Auditor Ambiental',
    text: 'Auditor Ambiental'
  },
  {
    value: 'Auditor de Qualidade',
    text: 'Auditor de Qualidade'
  },
  {
    value: 'Auditor Externo',
    text: 'Auditor Externo'
  },
  {
    value: 'Auditor Interno',
    text: 'Auditor Interno'
  },
  {
    value: 'Auxiliar de Almoxarifado',
    text: 'Auxiliar de Almoxarifado'
  },
  {
    value: 'Auxiliar de Almoxarife',
    text: 'Auxiliar de Almoxarife'
  },
  {
    value: 'Auxiliar de Arquivo',
    text: 'Auxiliar de Arquivo'
  },
  {
    value: 'Auxiliar de Banco de Dados',
    text: 'Auxiliar de Banco de Dados'
  },
  {
    value: 'Auxiliar de Biblioteca',
    text: 'Auxiliar de Biblioteca'
  },
  {
    value: 'Auxiliar de Comércio Exterior',
    text: 'Auxiliar de Comércio Exterior'
  },
  {
    value: 'Auxiliar de Contas a Pagar e Receber',
    text: 'Auxiliar de Contas a Pagar e Receber'
  },
  {
    value: 'Auxiliar de Controle de Qualidade',
    text: 'Auxiliar de Controle de Qualidade'
  },
  {
    value: 'Auxiliar de Cozinha',
    text: 'Auxiliar de Cozinha'
  },
  {
    value: 'Auxiliar de Crédito e Cobrança',
    text: 'Auxiliar de Crédito e Cobrança'
  },
  {
    value: 'Auxiliar de Custos',
    text: 'Auxiliar de Custos'
  },
  {
    value: 'Auxiliar de Distribuição',
    text: 'Auxiliar de Distribuição'
  },
  {
    value: 'Auxiliar de Engenharia de Segurança do Trabalho',
    text: 'Auxiliar de Engenharia de Segurança do Trabalho'
  },
  {
    value: 'Auviliar de Escritório',
    text: 'Auviliar de Escritório'
  },
  {
    value: 'Auxiliar de Fundição',
    text: 'Auxiliar de Fundição'
  },
  {
    value: 'Auxiliar de Instalação',
    text: 'Auxiliar de Instalação'
  },
  {
    value: 'Auxiliar de Limpeza',
    text: 'Auxiliar de Limpeza'
  },
  {
    value: 'Auxiliar de Manutenção Hidráulica',
    text: 'Auxiliar de Manutenção Hidráulica'
  },
  {
    value: 'Auxiliar de Padeiro',
    text: 'Auxiliar de Padeiro'
  },
  {
    value: 'Auxiliar de PCP',
    text: 'Auxiliar de PCP'
  },
  {
    value: 'Auxiliar de Programação',
    text: 'Auxiliar de Programação'
  },
  {
    value: 'Auxiliar de SAC',
    text: 'Auxiliar de SAC'
  },
  {
    value: 'Auxiliar de Segurança',
    text: 'Auxiliar de Segurança'
  },
  {
    value: 'Auxiliar de Serralheiro',
    text: 'Auxiliar de Serralheiro'
  },
  {
    value: 'Auxiliar de Suprimentos',
    text: 'Auxiliar de Suprimentos'
  },
  {
    value: 'Auxiliar de Topografia',
    text: 'Auxiliar de Topografia'
  },
  {
    value: 'Auxiliar de Transportes',
    text: 'Auxiliar de Transportes'
  },
  {
    value: 'Auxiliar de Usinagem',
    text: 'Auxiliar de Usinagem'
  },
  {
    value: 'Auxiliar de Veterinária',
    text: 'Auxiliar de Veterinária'
  },
  {
    value: 'Auxiliar de Web Design',
    text: 'Auxiliar de Web Design'
  },
  {
    value: 'Auxiliar Econômico',
    text: 'Auxiliar Econômico'
  },
  {
    value: 'Auxiliar Fiscal',
    text: 'Auxiliar Fiscal'
  },
  {
    value: 'Auxiliar Jurídico',
    text: 'Auxiliar Jurídico'
  },
  {
    value: 'Bartender',
    text: 'Bartender'
  },
  {
    value: 'Babá',
    text: 'Babá'
  },
  {
    value: 'Balconista',
    text: 'Balconista'
  },
  {
    value: 'Bancário',
    text: 'Bancário'
  },
  {
    value: 'Barman',
    text: 'Barman'
  },
  {
    value: 'Biblioteconista',
    text: 'Biblioteconista'
  },
  {
    value: 'Biólogo',
    text: 'Biólogo'
  },
  {
    value: 'Biomédico',
    text: 'Biomédico'
  },
  {
    value: 'Bioquímico',
    text: 'Bioquímico'
  },
  {
    value: 'Biotecnólogo',
    text: 'Biotecnólogo'
  },
  {
    value: 'Bombeiro',
    text: 'Bombeiro'
  },
  {
    value: 'Borracheiro',
    text: 'Borracheiro'
  },
  {
    value: 'Cabeleireiro',
    text: 'Cabeleireiro'
  },
  {
    value: 'Cadista',
    text: 'Cadista'
  },
  {
    value: 'Cardiologista',
    text: 'Cardiologista'
  },
  {
    value: 'Cartazista',
    text: 'Cartazista'
  },
  {
    value: 'Carteiro',
    text: 'Carteiro'
  },
  {
    value: 'Cenógrafo',
    text: 'Cenógrafo'
  },
  {
    value: 'Ceramista',
    text: 'Ceramista'
  },
  {
    value: 'Cineasta',
    text: 'Cineasta'
  },
  {
    value: 'Cinegrafista',
    text: 'Cinegrafista'
  },
  {
    value: 'Cirurgião Plástico',
    text: 'Cirurgião Plástico'
  },
  {
    value: 'Clínico Geral',
    text: 'Clínico Geral'
  },
  {
    value: 'Cobrador de Coletivo',
    text: 'Cobrador de Coletivo'
  },
  {
    value: 'Comissário de Bordo',
    text: 'Comissário de Bordo'
  },
  {
    value: 'Confeiteiro',
    text: 'Confeiteiro'
  },
  {
    value: 'Conferente',
    text: 'Conferente'
  },
  {
    value: 'Conferente de Loja',
    text: 'Conferente de Loja'
  },
  {
    value: 'Consultor Contábil',
    text: 'Consultor Contábil'
  },
  {
    value: 'Consultor de Marketing',
    text: 'Consultor de Marketing'
  },
  {
    value: 'Consultor de Moda',
    text: 'Consultor de Moda'
  },
  {
    value: 'Consultor de Qualidade',
    text: 'Consultor de Qualidade'
  },
  {
    value: 'Consultor de Vendas Externas',
    text: 'Consultor de Vendas Externas'
  },
  {
    value: 'Consultor de Viagens',
    text: 'Consultor de Viagens'
  },
  {
    value: 'Contador',
    text: 'Contador'
  },
  {
    value: 'Controlador de Tráfego',
    text: 'Controlador de Tráfego'
  },
  {
    value: 'Coordenador Administrativo',
    text: 'Coordenador Administrativo'
  },
  {
    value: 'Coordenador Comercial Externo',
    text: 'Coordenador Comercial Externo'
  },
  {
    value: 'Coordenador Comercial Interno',
    text: 'Coordenador Comercial Interno'
  },
  {
    value: 'Coordenador de Almoxarifado',
    text: 'Coordenador de Almoxarifado'
  },
  {
    value: 'Coordenador de Arquitetura',
    text: 'Coordenador de Arquitetura'
  },
  {
    value: 'Coordenador de Atendimento Comercial',
    text: 'Coordenador de Atendimento Comercial'
  },
  {
    value: 'Coordenador de Atendimento a Clientes',
    text: 'Coordenador de Atendimento a Clientes'
  },
  {
    value: 'Coordenador de Banco',
    text: 'Coordenador de Banco'
  },
  {
    value: 'Coordenador de Cargos e Salários',
    text: 'Coordenador de Cargos e Salários'
  },
  {
    value: 'Coordenador de Cobrança',
    text: 'Coordenador de Cobrança'
  },
  {
    value: 'Coordenador de Comércio Exterior',
    text: 'Coordenador de Comércio Exterior'
  },
  {
    value: 'Coordenador de Compras',
    text: 'Coordenador de Compras'
  },
  {
    value: 'Coordenador de Comunicação',
    text: 'Coordenador de Comunicação'
  },
  {
    value: 'Coordenador de Contabilidade',
    text: 'Coordenador de Contabilidade'
  },
  {
    value: 'Coordenador de Controladoria',
    text: 'Coordenador de Controladoria'
  },
  {
    value: 'Coordenador de Controle de Qualidade',
    text: 'Coordenador de Controle de Qualidade'
  },
  {
    value: 'Coordenador de Cozinha',
    text: 'Coordenador de Cozinha'
  },
  {
    value: 'Coordenador de Crédito e Cobrança',
    text: 'Coordenador de Crédito e Cobrança'
  },
  {
    value: 'Coordenador de Custos',
    text: 'Coordenador de Custos'
  },
  {
    value: 'Coordenador de Departamento Pessoal',
    text: 'Coordenador de Departamento Pessoal'
  },
  {
    value: 'Coordenador de Desenvolvimento de Produto',
    text: 'Coordenador de Desenvolvimento de Produto'
  },
  {
    value: 'Coordenador de Desenvolvimento de Sistemas',
    text: 'Coordenador de Desenvolvimento de Sistemas'
  },
  {
    value: 'Coordenador de Diagramação',
    text: 'Coordenador de Diagramação'
  },
  {
    value: 'Coordenador de Ecommerce',
    text: 'Coordenador de Ecommerce'
  },
  {
    value: 'Coordenador de Enfermagem',
    text: 'Coordenador de Enfermagem'
  },
  {
    value: 'Coordenador de Engenharia e Segurança do Trabalho',
    text: 'Coordenador de Engenharia e Segurança do Trabalho'
  },
  {
    value: 'Coordenador de Engenharia Elétrica',
    text: 'Coordenador de Engenharia Elétrica'
  },
  {
    value: 'Coordenador de Estoque',
    text: 'Coordenador de Estoque'
  },
  {
    value: 'Coordenador de Fundição',
    text: 'Coordenador de Fundição'
  },
  {
    value: 'Coordenador de Informática',
    text: 'Coordenador de Informática'
  },
  {
    value: 'Coordenador de Jornalismo',
    text: 'Coordenador de Jornalismo'
  },
  {
    value: 'Coordenador de Limpeza',
    text: 'Coordenador de Limpeza'
  },
  {
    value: 'Coordenador de Logística',
    text: 'Coordenador de Logística'
  },
  {
    value: 'Coordenador de Loja',
    text: 'Coordenador de Loja'
  },
  {
    value: 'Coordenador de Manutenção',
    text: 'Coordenador de Manutenção'
  },
  {
    value: 'Coordenador de Manutenção Elétrica',
    text: 'Coordenador de Manutenção Elétrica'
  },
  {
    value: 'Coordenador de Marketing',
    text: 'Coordenador de Marketing'
  },
  {
    value: 'Coordenador de PCP',
    text: 'Coordenador de PCP'
  },
  {
    value: 'Coordenador de Produção',
    text: 'Coordenador de Produção'
  },
  {
    value: 'Coordenador de Publicidade e Propaganda',
    text: 'Coordenador de Publicidade e Propaganda'
  },
  {
    value: 'Coordenador de Qualidade',
    text: 'Coordenador de Qualidade'
  },
  {
    value: 'Coordenador de Recrutamento e Seleção',
    text: 'Coordenador de Recrutamento e Seleção'
  },
  {
    value: 'Coordenador de Recursos Humanos',
    text: 'Coordenador de Recursos Humanos'
  },
  {
    value: 'Coordenador de Representação Comercial',
    text: 'Coordenador de Representação Comercial'
  },
  {
    value: 'Coordenador de SAC',
    text: 'Coordenador de SAC'
  },
  {
    value: 'Coordenador de Salão',
    text: 'Coordenador de Salão'
  },
  {
    value: 'Coordenador de Segurança Patrimonial',
    text: 'Coordenador de Segurança Patrimonial'
  },
  {
    value: 'Coordenador de Seguros',
    text: 'Coordenador de Seguros'
  },
  {
    value: 'Coordenador de Sistemas',
    text: 'Coordenador de Sistemas'
  },
  {
    value: 'Coordenador de Suporte Técnico',
    text: 'Coordenador de Suporte Técnico'
  },
  {
    value: 'Coordenador de Suprimentos',
    text: 'Coordenador de Suprimentos'
  },
  {
    value: 'Coordenador de Tecnologia da Informação',
    text: 'Coordenador de Tecnologia da Informação'
  },
  {
    value: 'Coordenador de Telecomunicações',
    text: 'Coordenador de Telecomunicações'
  },
  {
    value: 'Coordenador de Telemarketing Ativo',
    text: 'Coordenador de Telemarketing Ativo'
  },
  {
    value: 'Coordenador de Telemarketing Receptivo',
    text: 'Coordenador de Telemarketing Receptivo'
  },
  {
    value: 'Coordenador de Tesouraraia',
    text: 'Coordenador de Tesouraraia'
  },
  {
    value: 'Coordenador de Transportes',
    text: 'Coordenador de Transportes'
  },
  {
    value: 'Coordenador de Treinamento',
    text: 'Coordenador de Treinamento'
  },
  {
    value: 'Coordenador de Usinagem',
    text: 'Coordenador de Usinagem'
  },
  {
    value: 'Coordenador Econômico',
    text: 'Coordenador Econômico'
  },
  {
    value: 'Coordenador Financeiro',
    text: 'Coordenador Financeiro'
  },
  {
    value: 'Coordenador Fiscal',
    text: 'Coordenador Fiscal'
  },
  {
    value: 'Coordenador Químico',
    text: 'Coordenador Químico'
  },
  {
    value: 'Corretor de Seguros',
    text: 'Corretor de Seguros'
  },
  {
    value: 'Cozinheiro',
    text: 'Cozinheiro'
  },
  {
    value: 'Diretor Administrativo',
    text: 'Diretor Administrativo'
  },
  {
    value: 'Dentista',
    text: 'Dentista'
  },
  {
    value: 'Desenhista',
    text: 'Desenhista'
  },
  {
    value: 'Designer de Interiores',
    text: 'Designer de Interiores'
  },
  {
    value: 'Designer de Moda',
    text: 'Designer de Moda'
  },
  {
    value: 'Diagramador',
    text: 'Diagramador'
  },
  {
    value: 'Diretor Comercial Externo',
    text: 'Diretor Comercial Externo'
  },
  {
    value: 'Diretor Comercial Interno',
    text: 'Diretor Comercial Interno'
  },
  {
    value: 'Diretor de Cinema',
    text: 'Diretor de Cinema'
  },
  {
    value: 'Diretor de Prdução',
    text: 'Diretor de Prdução'
  },
  {
    value: 'Diretor de Representação Comercial',
    text: 'Diretor de Representação Comercial'
  },
  {
    value: 'Diretor de Recursos Humanos',
    text: 'Diretor de Recursos Humanos'
  },
  {
    value: 'Diretor de Tecnologia da Informação (TI)',
    text: 'Diretor de Tecnologia da Informação (TI)'
  },
  {
    value: 'Diretor de Marketing',
    text: 'Diretor de Marketing'
  },
  {
    value: 'Diretor Financeiro',
    text: 'Diretor Financeiro'
  },
  {
    value: 'Diretor de Informática',
    text: 'Diretor de Informática'
  },
  {
    value: 'Diretor de Jornalismo',
    text: 'Diretor de Jornalismo'
  },
  {
    value: 'Diretor de Suporte Técnico',
    text: 'Diretor de Suporte Técnico'
  },
  {
    value: 'Diretor de Logística',
    text: 'Diretor de Logística'
  },
  {
    value: 'Diretor de Departamento Pessoal',
    text: 'Diretor de Departamento Pessoal'
  },
  {
    value: 'Ecologista',
    text: 'Ecologista'
  },
  {
    value: 'Economista',
    text: 'Economista'
  },
  {
    value: 'Editor',
    text: 'Editor'
  },
  {
    value: 'Educador',
    text: 'Educador'
  },
  {
    value: 'Eletricista',
    text: 'Eletricista'
  },
  {
    value: 'Eletricista de Manutenção',
    text: 'Eletricista de Manutenção'
  },
  {
    value: 'Embalador',
    text: 'Embalador'
  },
  {
    value: 'Empreagdo (a) Doméstico (a)',
    text: 'Empreagdo (a) Doméstico (a)'
  },
  {
    value: 'Encanador',
    text: 'Encanador'
  },
  {
    value: 'Encarregado de Almoxarifado',
    text: 'Encarregado de Almoxarifado'
  },
  {
    value: 'Encarregado de PCP',
    text: 'Encarregado de PCP'
  },
  {
    value: 'Encarregado de Recursos Humanos',
    text: 'Encarregado de Recursos Humanos'
  },
  {
    value: 'Encarregado de Segurança',
    text: 'Encarregado de Segurança'
  },
  {
    value: 'Encarregado de Transporte',
    text: 'Encarregado de Transporte'
  },
  {
    value: 'Encarregado de Usinagem',
    text: 'Encarregado de Usinagem'
  },
  {
    value: 'Endocrinologista',
    text: 'Endocrinologista'
  },
  {
    value: 'Engenheiro Têxtil',
    text: 'Engenheiro Têxtil'
  },
  {
    value: 'Enfermeiro',
    text: 'Enfermeiro'
  },
  {
    value: 'Engenheiro Aeronáutico',
    text: 'Engenheiro Aeronáutico'
  },
  {
    value: 'Engenheiro Agrimensor',
    text: 'Engenheiro Agrimensor'
  },
  {
    value: 'Engenheiro Agrônomo',
    text: 'Engenheiro Agrônomo'
  },
  {
    value: 'Engenheiro Ambiental',
    text: 'Engenheiro Ambiental'
  },
  {
    value: 'Engenheiro Civil',
    text: 'Engenheiro Civil'
  },
  {
    value: 'Engenheiro Civil Junior',
    text: 'Engenheiro Civil Junior'
  },
  {
    value: 'Engenheiro Civil Pleno',
    text: 'Engenheiro Civil Pleno'
  },
  {
    value: 'Engenheiro Civil Sênior',
    text: 'Engenheiro Civil Sênior'
  },
  {
    value: 'Engenheiro da Computação',
    text: 'Engenheiro da Computação'
  },
  {
    value: 'Engenheiro de Alimentos',
    text: 'Engenheiro de Alimentos'
  },
  {
    value: 'Engenheiro de Engenharia e Segurança do Trabalho',
    text: 'Engenheiro de Engenharia e Segurança do Trabalho'
  },
  {
    value: 'Engenheiro de Produção',
    text: 'Engenheiro de Produção'
  },
  {
    value: 'Engenheiro de Suprimentos',
    text: 'Engenheiro de Suprimentos'
  },
  {
    value: 'Engenheiro de Telecomunicações',
    text: 'Engenheiro de Telecomunicações'
  },
  {
    value: 'Engenheiro Eletricista',
    text: 'Engenheiro Eletricista'
  },
  {
    value: 'Engenheiro Metalúrgico',
    text: 'Engenheiro Metalúrgico'
  },
  {
    value: 'Engenheiro Naval',
    text: 'Engenheiro Naval'
  },
  {
    value: 'Engenheiro Nuclear',
    text: 'Engenheiro Nuclear'
  },
  {
    value: 'Estagiário Comercial Externo',
    text: 'Estagiário Comercial Externo'
  },
  {
    value: 'Estagiário Comercial Interno',
    text: 'Estagiário Comercial Interno'
  },
  {
    value: 'Estagiário Administrativo',
    text: 'Estagiário Administrativo'
  },
  {
    value: 'Estagiário de Arquitetura',
    text: 'Estagiário de Arquitetura'
  },
  {
    value: 'Estagiário de Atendimento Comercial',
    text: 'Estagiário de Atendimento Comercial'
  },
  {
    value: 'Estagiário de Atendimento a Clientes',
    text: 'Estagiário de Atendimento a Clientes'
  },
  {
    value: 'Estagiário de Banco',
    text: 'Estagiário de Banco'
  },
  {
    value: 'Estagiário de Banco de Dados',
    text: 'Estagiário de Banco de Dados'
  },
  {
    value: 'Estagiário de Biomedicina',
    text: 'Estagiário de Biomedicina'
  },
  {
    value: 'Estagiário de Cobrança',
    text: 'Estagiário de Cobrança'
  },
  {
    value: 'Estagiário de Comércio Exterior',
    text: 'Estagiário de Comércio Exterior'
  },
  {
    value: 'Estagiário de Compras',
    text: 'Estagiário de Compras'
  },
  {
    value: 'Estagiário de Comunicação',
    text: 'Estagiário de Comunicação'
  },
  {
    value: 'Estagiário de Contabilidade',
    text: 'Estagiário de Contabilidade'
  },
  {
    value: 'Estagiário de Controladoria',
    text: 'Estagiário de Controladoria'
  },
  {
    value: 'Estagiário de Controle de Qualidade',
    text: 'Estagiário de Controle de Qualidade'
  },
  {
    value: 'Estagiário de Cozinha',
    text: 'Estagiário de Cozinha'
  },
  {
    value: 'Estagiário de Custos',
    text: 'Estagiário de Custos'
  },
  {
    value: 'Estagiário de Departamento Pessoal',
    text: 'Estagiário de Departamento Pessoal'
  },
  {
    value: 'Estagiário de Desenho Industrial',
    text: 'Estagiário de Desenho Industrial'
  },
  {
    value: 'Estagiário de Desenvolvimento de Produto',
    text: 'Estagiário de Desenvolvimento de Produto'
  },
  {
    value: 'Estagiário de Desenvolvimento de Sistemas',
    text: 'Estagiário de Desenvolvimento de Sistemas'
  },
  {
    value: 'Estagiário de Diagramação',
    text: 'Estagiário de Diagramação'
  },
  {
    value: 'Estagiário de Distribuição',
    text: 'Estagiário de Distribuição'
  },
  {
    value: 'Estagiário de Ecommerce',
    text: 'Estagiário de Ecommerce'
  },
  {
    value: 'Estagiário de Economia',
    text: 'Estagiário de Economia'
  },
  {
    value: 'Estagiário de Edificações',
    text: 'Estagiário de Edificações'
  },
  {
    value: 'Estagiário de Educação Física',
    text: 'Estagiário de Educação Física'
  },
  {
    value: 'Estagiário de Enfermagem',
    text: 'Estagiário de Enfermagem'
  },
  {
    value: 'Estagiário de Engenharia de Alimentos',
    text: 'Estagiário de Engenharia de Alimentos'
  },
  {
    value: 'Estagiário de Engenharia de Telecomunicações',
    text: 'Estagiário de Engenharia de Telecomunicações'
  },
  {
    value: 'Estagiário de Engenharia e Segurança do Trabalho',
    text: 'Estagiário de Engenharia e Segurança do Trabalho'
  },
  {
    value: 'Estagiário de Engenharia Elétrica',
    text: 'Estagiário de Engenharia Elétrica'
  },
  {
    value: 'Estagiário de Estoque',
    text: 'Estagiário de Estoque'
  },
  {
    value: 'Estagiário de Gastronomia',
    text: 'Estagiário de Gastronomia'
  },
  {
    value: 'Estagiário de Gestão Abiental',
    text: 'Estagiário de Gestão Abiental'
  },
  {
    value: 'Estagiário de Informática',
    text: 'Estagiário de Informática'
  },
  {
    value: 'Estagiário de Jornalismo',
    text: 'Estagiário de Jornalismo'
  },
  {
    value: 'Estagiário de Letras',
    text: 'Estagiário de Letras'
  },
  {
    value: 'Estagiário de Logística',
    text: 'Estagiário de Logística'
  },
  {
    value: 'Estagiário de Manutenção',
    text: 'Estagiário de Manutenção'
  },
  {
    value: 'Estagiário de Manutenção Elétrica',
    text: 'Estagiário de Manutenção Elétrica'
  },
  {
    value: 'Estagiário de Marketing',
    text: 'Estagiário de Marketing'
  },
  {
    value: 'Estagiário de PCP',
    text: 'Estagiário de PCP'
  },
  {
    value: 'Estagiário de Produção',
    text: 'Estagiário de Produção'
  },
  {
    value: 'Estagiário de Publicidade e Propaganda',
    text: 'Estagiário de Publicidade e Propaganda'
  },
  {
    value: 'Estagiário de Qualidade',
    text: 'Estagiário de Qualidade'
  },
  {
    value: 'Estagiário de Recrutamento e Seleção',
    text: 'Estagiário de Recrutamento e Seleção'
  },
  {
    value: 'Estagiário de Recursos Humanos',
    text: 'Estagiário de Recursos Humanos'
  },
  {
    value: 'Estagiário de Representação Comercial',
    text: 'Estagiário de Representação Comercial'
  },
  {
    value: 'Estagiário de SAC',
    text: 'Estagiário de SAC'
  },
  {
    value: 'Estagiário de Sistemas',
    text: 'Estagiário de Sistemas'
  },
  {
    value: 'Estagiário de Suporte Técnico',
    text: 'Estagiário de Suporte Técnico'
  },
  {
    value: 'Estagiário de Suprimentos',
    text: 'Estagiário de Suprimentos'
  },
  {
    value: 'Estagiário de Tecnologia da Informação (TI)',
    text: 'Estagiário de Tecnologia da Informação (TI)'
  },
  {
    value: 'Estagiário de Telecomunicaçãoes',
    text: 'Estagiário de Telecomunicaçãoes'
  },
  {
    value: 'Estagiário de Telemarketing Ativo',
    text: 'Estagiário de Telemarketing Ativo'
  },
  {
    value: 'Estagiário de Telemarketing Receptivo',
    text: 'Estagiário de Telemarketing Receptivo'
  },
  {
    value: 'Estagiário de Tesouraria',
    text: 'Estagiário de Tesouraria'
  },
  {
    value: 'Estagiário de Veterinária',
    text: 'Estagiário de Veterinária'
  },
  {
    value: 'Estagiário de Web Design',
    text: 'Estagiário de Web Design'
  },
  {
    value: 'Estagiário Financeiro',
    text: 'Estagiário Financeiro'
  },
  {
    value: 'Estagiário Fiscal',
    text: 'Estagiário Fiscal'
  },
  {
    value: 'Estagiário Químico',
    text: 'Estagiário Químico'
  },
  {
    value: 'Esteticista',
    text: 'Esteticista'
  },
  {
    value: 'Estilista',
    text: 'Estilista'
  },
  {
    value: 'Estoquista',
    text: 'Estoquista'
  },
  {
    value: 'Estoquista de Loja',
    text: 'Estoquista de Loja'
  },
  {
    value: 'Fundidor',
    text: 'Fundidor'
  },
  {
    value: 'Farmacêutico',
    text: 'Farmacêutico'
  },
  {
    value: 'Feirante',
    text: 'Feirante'
  },
  {
    value: 'Filósofo',
    text: 'Filósofo'
  },
  {
    value: 'Físico',
    text: 'Físico'
  },
  {
    value: 'Fisioterapeuta',
    text: 'Fisioterapeuta'
  },
  {
    value: 'Fonoaudiólogo',
    text: 'Fonoaudiólogo'
  },
  {
    value: 'Fotógrafo',
    text: 'Fotógrafo'
  },
  {
    value: 'Fresador',
    text: 'Fresador'
  },
  {
    value: 'Fresador CNC',
    text: 'Fresador CNC'
  },
  {
    value: 'Fresador Ferramenteiro',
    text: 'Fresador Ferramenteiro'
  },
  {
    value: 'Garçom',
    text: 'Garçom'
  },
  {
    value: 'Gari',
    text: 'Gari'
  },
  {
    value: 'Gastrônomo',
    text: 'Gastrônomo'
  },
  {
    value: 'Geofísico',
    text: 'Geofísico'
  },
  {
    value: 'Geográfo',
    text: 'Geográfo'
  },
  {
    value: 'Geólogo',
    text: 'Geólogo'
  },
  {
    value: 'Gerente Administrativo',
    text: 'Gerente Administrativo'
  },
  {
    value: 'Gerente Comercial Externo',
    text: 'Gerente Comercial Externo'
  },
  {
    value: 'Gerente Comercial Interno',
    text: 'Gerente Comercial Interno'
  },
  {
    value: 'Gerente Comercial Interno',
    text: 'Gerente Comercial Interno'
  },
  {
    value: 'Gerente de Atendimento a Clientes',
    text: 'Gerente de Atendimento a Clientes'
  },
  {
    value: 'Gerente de Atendimento Comercial',
    text: 'Gerente de Atendimento Comercial'
  },
  {
    value: 'Gerente de Banco',
    text: 'Gerente de Banco'
  },
  {
    value: 'Gerente de Cobrança',
    text: 'Gerente de Cobrança'
  },
  {
    value: 'Gerente de Comércio Exterior',
    text: 'Gerente de Comércio Exterior'
  },
  {
    value: 'Gerente de Compras',
    text: 'Gerente de Compras'
  },
  {
    value: 'Gerente de Comunicação',
    text: 'Gerente de Comunicação'
  },
  {
    value: 'Gerente de Contabilidade',
    text: 'Gerente de Contabilidade'
  },
  {
    value: 'Gerente de Contas',
    text: 'Gerente de Contas'
  },
  {
    value: 'Gerente de Controladoria',
    text: 'Gerente de Controladoria'
  },
  {
    value: 'Gerente de Controle de Qualidade',
    text: 'Gerente de Controle de Qualidade'
  },
  {
    value: 'Gerente de Cozinha',
    text: 'Gerente de Cozinha'
  },
  {
    value: 'Gerente de Departamento Pessoal',
    text: 'Gerente de Departamento Pessoal'
  },
  {
    value: 'Gerente de Desenvolvimento de Produto',
    text: 'Gerente de Desenvolvimento de Produto'
  },
  {
    value: 'Gerente de Desenvolvimento de Sistemas',
    text: 'Gerente de Desenvolvimento de Sistemas'
  },
  {
    value: 'Gerente de Distribuição',
    text: 'Gerente de Distribuição'
  },
  {
    value: 'Gerente de Ecommerce',
    text: 'Gerente de Ecommerce'
  },
  {
    value: 'Gerente de Enfermagem',
    text: 'Gerente de Enfermagem'
  },
  {
    value: 'Gerente de Engenharia Elétrica',
    text: 'Gerente de Engenharia Elétrica'
  },
  {
    value: 'Gerente de Estoque',
    text: 'Gerente de Estoque'
  },
  {
    value: 'Gerente de Hotelaria',
    text: 'Gerente de Hotelaria'
  },
  {
    value: 'Gerente de Informática',
    text: 'Gerente de Informática'
  },
  {
    value: 'Gerente de Jornalismo',
    text: 'Gerente de Jornalismo'
  },
  {
    value: 'Gerente de Logística',
    text: 'Gerente de Logística'
  },
  {
    value: 'Gerente de Loja',
    text: 'Gerente de Loja'
  },
  {
    value: 'Gerente de Manutenção',
    text: 'Gerente de Manutenção'
  },
  {
    value: 'Gerente de Manutenção Elétrica',
    text: 'Gerente de Manutenção Elétrica'
  },
  {
    value: 'Gerente de Marketing',
    text: 'Gerente de Marketing'
  },
  {
    value: 'Gerente de PCP',
    text: 'Gerente de PCP'
  },
  {
    value: 'Gerente de Produção',
    text: 'Gerente de Produção'
  },
  {
    value: 'Gerente de Publicidade e Propaganda',
    text: 'Gerente de Publicidade e Propaganda'
  },
  {
    value: 'Gerente de Qualidade',
    text: 'Gerente de Qualidade'
  },
  {
    value: 'Gerente de Recrutamento e Seleção',
    text: 'Gerente de Recrutamento e Seleção'
  },
  {
    value: 'Gerente de Recursos Humanos',
    text: 'Gerente de Recursos Humanos'
  },
  {
    value: 'Gerente de Representação Comercial',
    text: 'Gerente de Representação Comercial'
  },
  {
    value: 'Gerente de SAC',
    text: 'Gerente de SAC'
  },
  {
    value: 'Gerente de Salão',
    text: 'Gerente de Salão'
  },
  {
    value: 'Gerente de Segurança Patrimonial',
    text: 'Gerente de Segurança Patrimonial'
  },
  {
    value: 'Gerente de Seguros',
    text: 'Gerente de Seguros'
  },
  {
    value: 'Gerente de Sistemas',
    text: 'Gerente de Sistemas'
  },
  {
    value: 'Gerente de Suporte Técnico',
    text: 'Gerente de Suporte Técnico'
  },
  {
    value: 'Gerente de Suprimentos',
    text: 'Gerente de Suprimentos'
  },
  {
    value: 'Gerente de Tecnologia da Informação (TI)',
    text: 'Gerente de Tecnologia da Informação (TI)'
  },
  {
    value: 'Gerente de Telecomunicações',
    text: 'Gerente de Telecomunicações'
  },
  {
    value: 'Gerente de Telemarketing Ativo',
    text: 'Gerente de Telemarketing Ativo'
  },
  {
    value: 'Gerente de Telemarketing Receptivo',
    text: 'Gerente de Telemarketing Receptivo'
  },
  {
    value: 'Gerente de Tesouraria',
    text: 'Gerente de Tesouraria'
  },
  {
    value: 'Gerente de Transportes',
    text: 'Gerente de Transportes'
  },
  {
    value: 'Gerente Financeiro',
    text: 'Gerente Financeiro'
  },
  {
    value: 'Gerente Fiscal',
    text: 'Gerente Fiscal'
  },
  {
    value: 'Gerente Químico',
    text: 'Gerente Químico'
  },
  {
    value: 'Geriatra',
    text: 'Geriatra'
  },
  {
    value: 'Gestor Ambiental',
    text: 'Gestor Ambiental'
  },
  {
    value: 'Ginecologista',
    text: 'Ginecologista'
  },
  {
    value: 'Governanta',
    text: 'Governanta'
  },
  {
    value: 'Homeopata',
    text: 'Homeopata'
  },
  {
    value: 'Historiador',
    text: 'Historiador'
  },
  {
    value: 'Inspetor de Controle de Qualidade',
    text: 'Inspetor de Controle de Qualidade'
  },
  {
    value: 'Instalador de Acessórios Automotivos',
    text: 'Instalador de Acessórios Automotivos'
  },
  {
    value: 'Instalador de Alarmes',
    text: 'Instalador de Alarmes'
  },
  {
    value: 'Instalador de Cabeamento Estruturado',
    text: 'Instalador de Cabeamento Estruturado'
  },
  {
    value: 'Instalador de Telecomunicações',
    text: 'Instalador de Telecomunicações'
  },
  {
    value: 'Instrutor de Treinamento',
    text: 'Instrutor de Treinamento'
  },
  {
    value: 'Jornalista',
    text: 'Jornalista'
  },
  {
    value: 'Jardineiro',
    text: 'Jardineiro'
  },
  {
    value: 'Líder de Produção',
    text: 'Líder de Produção'
  },
  {
    value: 'Líder de Usinagem',
    text: 'Líder de Usinagem'
  },
  {
    value: 'Locutor',
    text: 'Locutor'
  },
  {
    value: 'Maestro',
    text: 'Maestro'
  },
  {
    value: 'Mandrilhador',
    text: 'Mandrilhador'
  },
  {
    value: 'Manicure/Pedicure',
    text: 'Manicure/Pedicure'
  },
  {
    value: 'Manobrista',
    text: 'Manobrista'
  },
  {
    value: 'Maquiador',
    text: 'Maquiador'
  },
  {
    value: 'Marceneiro',
    text: 'Marceneiro'
  },
  {
    value: 'Marinheiro',
    text: 'Marinheiro'
  },
  {
    value: 'Matemático',
    text: 'Matemático'
  },
  {
    value: 'Mecânico',
    text: 'Mecânico'
  },
  {
    value: 'Mecânico de Automóveis',
    text: 'Mecânico de Automóveis'
  },
  {
    value: 'Mecânico de Manutenção Hidráulica',
    text: 'Mecânico de Manutenção Hidráulica'
  },
  {
    value: 'Médico',
    text: 'Médico'
  },
  {
    value: 'MestredeObras',
    text: 'MestredeObras'
  },
  {
    value: 'Modelo',
    text: 'Modelo'
  },
  {
    value: 'Monitor de Atendimento a Clientes',
    text: 'Monitor de Atendimento a Clientes'
  },
  {
    value: 'Monitor de Telemarketing Ativo',
    text: 'Monitor de Telemarketing Ativo'
  },
  {
    value: 'Monitor de Telemarketing Receptivo',
    text: 'Monitor de Telemarketing Receptivo'
  },
  {
    value: 'Motoboy',
    text: 'Motoboy'
  },
  {
    value: 'Motorista',
    text: 'Motorista'
  },
  {
    value: 'Neurologista',
    text: 'Neurologista'
  },
  {
    value: 'Nutricionista',
    text: 'Nutricionista'
  },
  {
    value: 'Obstetra',
    text: 'Obstetra'
  },
  {
    value: 'Oftalmologista',
    text: 'Oftalmologista'
  },
  {
    value: 'Operador de Atendimento a Clientes',
    text: 'Operador de Atendimento a Clientes'
  },
  {
    value: 'Operador de Caixa',
    text: 'Operador de Caixa'
  },
  {
    value: 'Operador de Fundição',
    text: 'Operador de Fundição'
  },
  {
    value: 'Operador de Guilhotina',
    text: 'Operador de Guilhotina'
  },
  {
    value: 'Operador de Logística',
    text: 'Operador de Logística'
  },
  {
    value: 'Operador de Produção',
    text: 'Operador de Produção'
  },
  {
    value: 'Operador de SAC',
    text: 'Operador de SAC'
  },
  {
    value: 'Operador de Telemarketing Ativo',
    text: 'Operador de Telemarketing Ativo'
  },
  {
    value: 'Operador de Telemarketing Receptivo',
    text: 'Operador de Telemarketing Receptivo'
  },
  {
    value: 'Operador de Torno',
    text: 'Operador de Torno'
  },
  {
    value: 'Operador de Usinagem',
    text: 'Operador de Usinagem'
  },
  {
    value: 'Ortopedista',
    text: 'Ortopedista'
  },
  {
    value: 'Otorrinolaringologista',
    text: 'Otorrinolaringologista'
  },
  {
    value: 'Psicólogo',
    text: 'Psicólogo'
  },
  {
    value: 'Padeiro',
    text: 'Padeiro'
  },
  {
    value: 'Pescador',
    text: 'Pescador'
  },
  {
    value: 'Paisagista',
    text: 'Paisagista'
  },
  {
    value: 'Pediatra',
    text: 'Pediatra'
  },
  {
    value: 'Pedreiro',
    text: 'Pedreiro'
  },
  {
    value: 'Pintor',
    text: 'Pintor'
  },
  {
    value: 'Podólogo',
    text: 'Podólogo'
  },
  {
    value: 'Polidor',
    text: 'Polidor'
  },
  {
    value: 'Produtor Editorial',
    text: 'Produtor Editorial'
  },
  {
    value: 'Professor de Educação',
    text: 'Professor de Educação'
  },
  {
    value: 'Professor de Letras',
    text: 'Professor de Letras'
  },
  {
    value: 'Programador de Centro de Usinagem',
    text: 'Programador de Centro de Usinagem'
  },
  {
    value: 'Programador de PCP',
    text: 'Programador de PCP'
  },
  {
    value: 'Promotor Bancário',
    text: 'Promotor Bancário'
  },
  {
    value: 'Químico',
    text: 'Químico'
  },
  {
    value: 'Recepcionista',
    text: 'Recepcionista'
  },
  {
    value: 'Radialista',
    text: 'Radialista'
  },
  {
    value: 'Radiologista',
    text: 'Radiologista'
  },
  {
    value: 'Redator',
    text: 'Redator'
  },
  {
    value: 'Repórter',
    text: 'Repórter'
  },
  {
    value: 'Representante Comercial',
    text: 'Representante Comercial'
  },
  {
    value: 'Supervisor Administrativo',
    text: 'Supervisor Administrativo'
  },
  {
    value: 'Secretária',
    text: 'Secretária'
  },
  {
    value: 'Serralheiro',
    text: 'Serralheiro'
  },
  {
    value: 'Supervisor Comercial Externo',
    text: 'Supervisor Comercial Externo'
  },
  {
    value: 'Supervisor Comercial Interno',
    text: 'Supervisor Comercial Interno'
  },
  {
    value: 'Supervisor de Almoxarifado',
    text: 'Supervisor de Almoxarifado'
  },
  {
    value: 'Supervisor de Atendimento a Clientes',
    text: 'Supervisor de Atendimento a Clientes'
  },
  {
    value: 'Supervisor de Atendimento Comercial',
    text: 'Supervisor de Atendimento Comercial'
  },
  {
    value: 'Supervisor de Banco',
    text: 'Supervisor de Banco'
  },
  {
    value: 'Supervisor de Cargos e Salários',
    text: 'Supervisor de Cargos e Salários'
  },
  {
    value: 'Supervisor de Cobrança',
    text: 'Supervisor de Cobrança'
  },
  {
    value: 'Supervisor de Comércio Exterior',
    text: 'Supervisor de Comércio Exterior'
  },
  {
    value: 'Supervisor de Compras',
    text: 'Supervisor de Compras'
  },
  {
    value: 'Supervisor de Comunicação',
    text: 'Supervisor de Comunicação'
  },
  {
    value: 'Supervisor de Contabilidade',
    text: 'Supervisor de Contabilidade'
  },
  {
    value: 'Supervisor de Controladoria',
    text: 'Supervisor de Controladoria'
  },
  {
    value: 'Supervisor de Controle de Qualidade',
    text: 'Supervisor de Controle de Qualidade'
  },
  {
    value: 'Supervisor de Cozinha',
    text: 'Supervisor de Cozinha'
  },
  {
    value: 'Supervisor de Crédito e Cobrança',
    text: 'Supervisor de Crédito e Cobrança'
  },
  {
    value: 'Supervisor de Custos',
    text: 'Supervisor de Custos'
  },
  {
    value: 'Supervisor de Departamento Pessoal',
    text: 'Supervisor de Departamento Pessoal'
  },
  {
    value: 'Supervisor de Desenvolvimento de Produto',
    text: 'Supervisor de Desenvolvimento de Produto'
  },
  {
    value: 'Supervisor de Desenvolvimento de Sistemas',
    text: 'Supervisor de Desenvolvimento de Sistemas'
  },
  {
    value: 'Supervisor de Distribuição',
    text: 'Supervisor de Distribuição'
  },
  {
    value: 'Supervisor de Ecommerce',
    text: 'Supervisor de Ecommerce'
  },
  {
    value: 'Supervisor de Enfermagem',
    text: 'Supervisor de Enfermagem'
  },
  {
    value: 'Supervisor de Engenharia e Segurança do Trabalho',
    text: 'Supervisor de Engenharia e Segurança do Trabalho'
  },
  {
    value: 'Supervisor de Engenharia Elétrica',
    text: 'Supervisor de Engenharia Elétrica'
  },
  {
    value: 'Supervisor de Estoque',
    text: 'Supervisor de Estoque'
  },
  {
    value: 'Supervisor de Fundição',
    text: 'Supervisor de Fundição'
  },
  {
    value: 'Supervisor de Informática',
    text: 'Supervisor de Informática'
  },
  {
    value: 'Supervisor de Limpeza',
    text: 'Supervisor de Limpeza'
  },
  {
    value: 'Supervisor de Logística',
    text: 'Supervisor de Logística'
  },
  {
    value: 'Supervisor de loja',
    text: 'Supervisor de loja'
  },
  {
    value: 'Supervisor de Manutenção',
    text: 'Supervisor de Manutenção'
  },
  {
    value: 'Supervisor de Marketing',
    text: 'Supervisor de Marketing'
  },
  {
    value: 'Supervisor de Manutenção Elétrica',
    text: 'Supervisor de Manutenção Elétrica'
  },
  {
    value: 'Supervisor de PCP',
    text: 'Supervisor de PCP'
  },
  {
    value: 'Supervisor de Produção',
    text: 'Supervisor de Produção'
  },
  {
    value: 'Supervisor de Publicidade e Propaganda',
    text: 'Supervisor de Publicidade e Propaganda'
  },
  {
    value: 'Supervisor de Qualidade',
    text: 'Supervisor de Qualidade'
  },
  {
    value: 'Supervisor de Recrutamento e Seleção',
    text: 'Supervisor de Recrutamento e Seleção'
  },
  {
    value: 'Supervisor de Recursos Humanos',
    text: 'Supervisor de Recursos Humanos'
  },
  {
    value: 'Supervisor de Representação Comercial',
    text: 'Supervisor de Representação Comercial'
  },
  {
    value: 'Supervisor de SAC',
    text: 'Supervisor de SAC'
  },
  {
    value: 'Supervisor de Salão',
    text: 'Supervisor de Salão'
  },
  {
    value: 'Supervisor de Segurança',
    text: 'Supervisor de Segurança'
  },
  {
    value: 'Supervisor de Segurança Patrimonial',
    text: 'Supervisor de Segurança Patrimonial'
  },
  {
    value: 'Supervisor de Seguros',
    text: 'Supervisor de Seguros'
  },
  {
    value: 'Supervisor de Sistemas',
    text: 'Supervisor de Sistemas'
  },
  {
    value: 'Supervisor de Suporte Técnico',
    text: 'Supervisor de Suporte Técnico'
  },
  {
    value: 'Supervisor de Suprimentos',
    text: 'Supervisor de Suprimentos'
  },
  {
    value: 'Supervisor de Tecnologia da Informação (TI)',
    text: 'Supervisor de Tecnologia da Informação (TI)'
  },
  {
    value: 'Supervisor de Telecomunicações',
    text: 'Supervisor de Telecomunicações'
  },
  {
    value: 'Supervisor de Telemarketing Ativo',
    text: 'Supervisor de Telemarketing Ativo'
  },
  {
    value: 'Supervisor de Telemarketing Receptivo',
    text: 'Supervisor de Telemarketing Receptivo'
  },
  {
    value: 'Supervisor de Tesouraria',
    text: 'Supervisor de Tesouraria'
  },
  {
    value: 'Supervisor de Transportes',
    text: 'Supervisor de Transportes'
  },
  {
    value: 'Supervisor de Usinagem',
    text: 'Supervisor de Usinagem'
  },
  {
    value: 'Supervisor Econômico',
    text: 'Supervisor Econômico'
  },
  {
    value: 'Supervisor Financeiro',
    text: 'Supervisor Financeiro'
  },
  {
    value: 'Supervisor Fiscal',
    text: 'Supervisor Fiscal'
  },
  {
    value: 'Técnico Ambiental',
    text: 'Técnico Ambiental'
  },
  {
    value: 'Técnico de Controle de Qualidade',
    text: 'Técnico de Controle de Qualidade'
  },
  {
    value: 'Técnico de Edificações',
    text: 'Técnico de Edificações'
  },
  {
    value: 'Técnico de Suporte',
    text: 'Técnico de Suporte'
  },
  {
    value: 'Técnico em Manutenção Elétrica',
    text: 'Técnico em Manutenção Elétrica'
  },
  {
    value: 'Técnico em Análise Clínica',
    text: 'Técnico em Análise Clínica'
  },
  {
    value: 'Técnico em Análise Química',
    text: 'Técnico em Análise Química'
  },
  {
    value: 'Técnico em Automação',
    text: 'Técnico em Automação'
  },
  {
    value: 'Técnico em Guia de Turismo',
    text: 'Técnico em Guia de Turismo'
  },
  {
    value: 'Técnico em Hardware',
    text: 'Técnico em Hardware'
  },
  {
    value: 'Técnico em Metalúrgica',
    text: 'Técnico em Metalúrgica'
  },
  {
    value: 'Técnico em Redes',
    text: 'Técnico em Redes'
  },
  {
    value: 'Técnico em Secretariado',
    text: 'Técnico em Secretariado'
  },
  {
    value: 'Técnico em Secretariado Escolar',
    text: 'Técnico em Secretariado Escolar'
  },
  {
    value: 'Técnico em Transações Imobiliárias',
    text: 'Técnico em Transações Imobiliárias'
  },
  {
    value: 'Técnico em Zootecnia',
    text: 'Técnico em Zootecnia'
  },
  {
    value: 'Técnico Instalador',
    text: 'Técnico Instalador'
  },
  {
    value: 'Técnico em Telecomunicações',
    text: 'Técnico em Telecomunicações'
  },
  {
    value: 'Tesoureiro',
    text: 'Tesoureiro'
  },
  {
    value: 'Topógrafo',
    text: 'Topógrafo'
  },
  {
    value: 'Tradutor',
    text: 'Tradutor'
  },
  {
    value: 'Traumatologista',
    text: 'Traumatologista'
  },
  {
    value: 'Turismólogo',
    text: 'Turismólogo'
  },
  {
    value: 'Urologista',
    text: 'Urologista'
  },
  {
    value: 'Web Design',
    text: 'Web Design'
  },
  {
    value: 'Web Design Sênior',
    text: 'Web Design Sênior'
  },
  {
    value: 'Web Design Junior',
    text: 'Web Design Junior'
  },
  {
    value: 'Zelador',
    text: 'Zelador'
  },
  {
    value: 'Zootecnista',
    text: 'Zootecnista'
  },
  {
    value: 'Outra',
    text: 'Outra'
  }
];
